var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gl-modal',{ref:"modal",attrs:{"modal-id":_vm.modalId,"data-qa-selector":"invite_members_modal_content","data-testid":"invite-modal","size":"sm","title":_vm.modalTitle,"header-close-label":_vm.$options.HEADER_CLOSE_LABEL,"action-primary":_vm.actionPrimary,"action-cancel":_vm.actionCancel},on:{"shown":_vm.onShowModal,"primary":_vm.onSubmit,"cancel":_vm.onCloseModal,"hidden":_vm.onReset}},[_c('content-transition',{staticClass:"gl-display-grid",attrs:{"transition-name":"invite-modal-transition","slots":_vm.contentSlots,"current-slot":_vm.currentSlot},scopedSlots:_vm._u([{key:_vm.$options.DEFAULT_SLOT,fn:function(){return [_c('div',{staticClass:"gl-display-flex",attrs:{"data-testid":"modal-base-intro-text"}},[_vm._t("intro-text-before"),_vm._v(" "),_c('p',[_c('gl-sprintf',{attrs:{"message":_vm.introText},scopedSlots:_vm._u([{key:"strong",fn:function(ref){
var content = ref.content;
return [_c('strong',[_vm._v(_vm._s(content))])]}}])})],1),_vm._v(" "),_vm._t("intro-text-after")],2),_vm._v(" "),_vm._t("alert"),_vm._v(" "),_c('gl-form-group',{attrs:{"invalid-feedback":_vm.invalidFeedbackMessage,"state":_vm.exceptionState,"data-testid":"members-form-group"},scopedSlots:_vm._u([{key:"description",fn:function(){return [(_vm.reachedLimit)?_c('gl-icon',{attrs:{"name":"lock"}}):_vm._e(),_vm._v("\n          "+_vm._s(_vm.formGroupDescription)+"\n        ")]},proxy:true}])},[_vm._v(" "),_c('label',{class:_vm.selectLabelClass,attrs:{"id":_vm.selectLabelId}},[_vm._v(_vm._s(_vm.labelSearchField))]),_vm._v(" "),(_vm.reachedLimit)?_c('gl-form-input',{attrs:{"data-testid":"disabled-input","disabled":""}}):_vm._t("select",null,null,{ exceptionState: _vm.exceptionState, labelId: _vm.selectLabelId })],2),_vm._v(" "),(!_vm.reachedLimit)?[_c('label',{staticClass:"gl-font-weight-bold"},[_vm._v(_vm._s(_vm.$options.ACCESS_LEVEL))]),_vm._v(" "),_c('div',{staticClass:"gl-mt-2 gl-w-half gl-xs-w-full"},[_c('gl-dropdown',_vm._b({staticClass:"gl-shadow-none gl-w-full",attrs:{"data-qa-selector":"access_level_dropdown","text":_vm.selectedRoleName}},'gl-dropdown',_vm.$attrs,false),[_vm._l((_vm.accessLevels),function(key,item){return [_c('gl-dropdown-item',{key:key,attrs:{"active-class":"is-active","is-check-item":"","is-checked":key === _vm.selectedAccessLevel},on:{"click":function($event){return _vm.changeSelectedItem(key)}}},[_c('div',[_vm._v(_vm._s(item))])])]})],2)],1),_vm._v(" "),_c('div',{staticClass:"gl-mt-2 gl-w-half gl-xs-w-full"},[_c('gl-sprintf',{attrs:{"message":_vm.$options.READ_MORE_TEXT},scopedSlots:_vm._u([{key:"link",fn:function(ref){
var content = ref.content;
return [_c('gl-link',{attrs:{"href":_vm.helpLink,"target":"_blank"}},[_vm._v(_vm._s(content))])]}}],null,false,4000374941)})],1),_vm._v(" "),_c('label',{staticClass:"gl-mt-5 gl-display-block",attrs:{"for":"expires_at"}},[_vm._v(_vm._s(_vm.$options.ACCESS_EXPIRE_DATE))]),_vm._v(" "),_c('div',{staticClass:"gl-mt-2 gl-w-half gl-xs-w-full gl-display-inline-block"},[_c('gl-datepicker',{staticClass:"gl-display-inline!",attrs:{"min-date":_vm.minDate,"target":null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedDate = ref.formattedDate;
return [_c('gl-form-input',{staticClass:"gl-w-full",attrs:{"value":formattedDate,"placeholder":_vm.__("YYYY-MM-DD")}})]}}],null,false,3772502206),model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_vm._v(" "),_vm._t("form-after")]:_vm._e()]},proxy:true},_vm._l((_vm.extraSlots),function(ref){
var key = ref.key;
return {key:key,fn:function(){return [_vm._t(key)]},proxy:true}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }